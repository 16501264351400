import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import * as components from '../../cms-driven';
import AnalyticsContentEvent from '../../analytics/content-event';
import analyticsBus from '#/analytics/analyticsBus';
import dfpTargetingCreator from '#/lib/dfp-targeting-creator';
import { getCurrentUrl } from '#/reducers/app';
import { CLICK } from '#/analytics/constants';
import { getEventType, getContentTitle } from '#/analytics/helpers/dcs';
import { getZonePageResource } from '#/selectors/resources';
import { emitContentInteractOp } from '#/analytics/bertie/events';
import { exposeActiveTestData } from '#/lib/optimizely-manager';

export function ZoneContainer({ zonePageResource, ...otherProps }) {
  const { experiments = [] } = zonePageResource.data;
  experiments.map(experiment => exposeActiveTestData(experiment));
  return (
    <div className="main zone" role="main">
      <div className="content-section">
        <components.ComponentTree
          components={components}
          data={zonePageResource.data.content.main}
          {...otherProps}
        />
      </div>
      <AnalyticsContentEvent
        data={zonePageResource.data}
        pageName="zone"
        sectionOrder={['main']}
      />
    </div>
  );
}

ZoneContainer.propTypes = {
  zonePageResource: PropTypes.shape({
    data: PropTypes.shape({
      meta: PropTypes.object,
      content: PropTypes.shape({
        main: PropTypes.array.isRequired
      }).isRequired
    }).isRequired
  })
};

const mapStateToProps = state => ({
  currentUrl: getCurrentUrl(state),
  zonePageResource: getZonePageResource(state)
});

@connect(mapStateToProps)
class Zone extends React.Component {
  static propTypes = {
    currentUrl: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    zonePageResource: PropTypes.shape({
      params: PropTypes.shape({
        pageName: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  };

  static childContextTypes = {
    currentUrl: PropTypes.string
  };

  getChildContext() {
    return {
      currentUrl: this.props.currentUrl
    };
  }

  buttonBasicEvent = (data, interactionType) => {
    return {
      action: 'now',
      type: 'zone',
      value: getContentTitle(data),
      interactionType: interactionType || CLICK
    };
  };

  contentEvent = (data, interactionType) => {
    emitContentInteractOp({
      displayArea: 'm-of',
      pageType: 'zone',
      panel: [
        {
          campaignId: data.contentId ? data.contentId : data.catId,
          campaignTitle: getContentTitle(data),
          posInModule: data.posInPanel + 1,
          contentSegment: data.segments
        }
      ],
      modulePosition: data.placement,
      interactionType:
        typeof interactionType === 'string' ? interactionType : CLICK
    });
  };

  componentClickHandler(componentData, interactionType) {
    const type = getEventType(componentData, interactionType);

    if (componentData?.catId === 'EventButton') {
      const payload =
        !componentData.button.isFeedback &&
        this.buttonBasicEvent(componentData, interactionType);
      payload && analyticsBus().emit(type, payload);
    } else {
      this.contentEvent(componentData, interactionType);
    }
  }

  render() {
    const { currentUrl, user, zonePageResource } = this.props;

    const props = {
      ...this.props,
      shared: {
        componentClickHandler: this.componentClickHandler.bind(this)
      },
      currentUrl
    };

    const targeting = dfpTargetingCreator({
      user,
      zone: zonePageResource?.params?.pageName
    });

    return (
      <div role="main" className="main">
        <components.DfpContainer pageId="zone" targeting={targeting} />
        <ZoneContainer {...props} />
      </div>
    );
  }
}

export default Zone;
